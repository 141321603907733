/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useRef, useState } from "react"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { useQuery } from "react-query"
import { Avatar, Box, Button, IconButton, Tooltip } from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { Link } from "react-router-dom"
import { AttachMoney, Edit, Refresh } from "@mui/icons-material"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import customFilterPanel from "../../Component/customFilter.jsx"
import Unauthorized from "../../Component/unauthorized.jsx"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import HasPermission from "../../Utils/access.js"
import CustomExportOptions from "../../Component/customExport.jsx"
import filterModel from "../../Utils/filterModel.js"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import handleLoyaltiesExport from "../../Utils/File Export Formatter/layaltiesExportFormatter.js"
import {
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_VERSION,
} from "../../Utils/config.js"
import LoyaltyAdjustment from "./loyaltyAdjustment.jsx"

function Loyalties() {
    // USESTATES & USEREFS
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        created_at: "is",
        updated_at: "is",
        balance: "=",
        email: "contains",
        first_name: "contains",
        middle_name: "contains",
        last_name: "contains",
        phone: "contains",
        gender: "is",
        customer_type: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [open, setOpen] = useState(false)
    const loyaltyData = useRef()
    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "loyalties",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`system/loyalties`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters)
                    ),
                    sort: JSON.stringify(sort),
                    search: globalFilter,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get all loyalties"),
        }
    )

    // HELPERS
    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = (company) => {
        loyaltyData.current = company
        setOpen(true)
        return loyaltyData.current
    }
    const getAvatar = useCallback(
        ({ cell }) => (
            <Avatar
                sx={{
                    width: 50,
                    height: 50,
                }}
                src={
                    cell?.row?.original?.profile_picture?.includes("onde")
                        ? cell?.row?.original?.profile_picture
                        : `${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${cell?.row?.original?.profile_picture}`
                }
            />
        ),
        []
    )
    const getCustomerType = useCallback(
        ({ row }) =>
            row?.original?.loyalty_balance?.customer_type !== "" &&
            row?.original?.loyalty_balance?.customer_type !== undefined && (
                <Button
                    key={row?.original?.id}
                    size="small"
                    disableElevation
                    variant="contained"
                    sx={{
                        backgroundColor: "#f5f5f5",
                        color:
                            row?.original?.loyalty_balance?.customer_type ===
                            "DRIVER"
                                ? "#fbcf3b"
                                : "black",
                        "&: hover": {
                            backgroundColor: "#f5f5f5",
                            color:
                                row?.original?.loyalty_balance
                                    ?.customer_type === "DRIVER"
                                    ? "#fbcf3b"
                                    : "black",
                        },
                        fontWeight: "bold",
                    }}
                >
                    {row?.original?.loyalty_balance?.customer_type}
                </Button>
            ),
        []
    )
    const action = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    pr: ".5em",
                    gap: 0.5,
                }}
            >
                {HasPermission("point adjustment") && (
                    <Tooltip title="Adjust Point">
                        <IconButton
                            size="small"
                            sx={{ color: "primary.main" }}
                            onClick={() => handleOpen(row?.original)}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                )}
                <Link
                    to={`${row.original.id}/transactions`}
                    state={{
                        loyaltyData: row.original,
                    }}
                >
                    <Tooltip title="Transactions">
                        <IconButton size="small" sx={{ color: "primary.main" }}>
                            <AttachMoney />
                        </IconButton>
                    </Tooltip>
                </Link>
            </Box>
        ),
        []
    )

    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 150,
                accessorKey: "image_url",
                header: "Photo",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                align: "center",
                Cell: getAvatar,
            },
            {
                size: 240,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ row }) =>
                    DateTime.fromISO(
                        row?.original?.loyalty_balance?.created_at
                    ).toLocaleString(DateTime.DATETIME_MED),
                Filter: datePicker,
            },
            {
                accessorKey: "first_name",
                header: "First Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "middle_name",
                header: "Middle Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "last_name",
                header: "Last Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "phone",
                header: "Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "email",
                header: "Email",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 230,
                accessorKey: "gender",
                header: "Gender",
                filterVariant: "select",
                filterSelectOptions: ["Male", "Female"],
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "customer_type",
                header: "Customer Type",
                filterVariant: "select",
                filterSelectOptions: ["DRIVER", "USER"],
                Cell: getCustomerType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "balance",
                header: "Points",
                filterVariant: "number",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        row?.original?.loyalty_balance?.balance
                    )}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "updated_at",
                header: "Updated At",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ row }) =>
                    DateTime.fromISO(
                        row?.original?.loyalty_balance?.updated_at
                    ).toLocaleString(DateTime.DATETIME_MED),
                Filter: datePicker,
            },
            {
                accessorKey: "action",
                header: "Action",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: action,
            },
        ],
        [action, getAvatar, getCustomerType]
    )

    // RENDERING
    if (!HasPermission("get all loyalties")) {
        return <Unauthorized />
    }

    return (
        <Box sx={{ height: "100%" }}>
            <Box>
                {open && (
                    <LoyaltyAdjustment
                        open={open}
                        refetch={refetch}
                        onClose={handleClose}
                        loyaltyData={loyaltyData.current}
                    />
                )}
            </Box>
            <MaterialReactTable
                data={data?.data?.data ?? []}
                columns={columns}
                initialState={{
                    columnPinning: {
                        left: ["mrt-row-select", "photo"],
                        right: ["action"],
                    },
                }}
                enableColumnResizing
                enableColumnFilterModes
                enableStickyHeader
                enableColumnOrdering
                enableRowSelection
                enablePinning
                manualFiltering
                manualPagination
                manualSorting
                filterFns={{
                    after: (row, filterValue) =>
                        row.customField === filterValue,
                }}
                muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                    helperText: `Filter Mode: ${filterFns[column?.id]}`,
                })}
                onColumnFiltersChange={setColumnFilters}
                onColumnFilterFnsChange={setFilterFns}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <Tooltip arrow title="Refresh Data">
                            <IconButton onClick={() => refetch()}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <CustomExportOptions
                            table={table}
                            model={filterModel(filterFns, columnFilters)}
                            page="/system/loyalties"
                            formatter={handleLoyaltiesExport}
                        />
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                    </>
                )}
                muiTableBodyCellProps={({ table, column }) => {
                    const columnName = column.id
                    const pinnedState = table.getState().columnPinning

                    const isLeftPinned = pinnedState?.left?.some(
                        (el) => el === columnName
                    )
                    const isRightPinned = pinnedState?.right?.some(
                        (el) => el === columnName
                    )
                    if (isLeftPinned || isRightPinned)
                        return {
                            sx: {
                                "&.MuiTableCell-root": {
                                    boxShadow: isRightPinned
                                        ? "-7px 0px 10px -1.7px lightgray"
                                        : "7px 0px 10px -1.7px lightgray",
                                },
                            },
                        }
                    return null
                }}
                muiTableContainerProps={{
                    sx: { maxHeight: `calc(100vh - 225px)` },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        "& .Mui-TableHeadCell-Content": {
                            justifyContent: "space-between",
                        },
                    },
                }}
                rowCount={data?.data?.meta_data?.total ?? 0}
                state={{
                    columnFilters,
                    filterFns,
                    globalFilter,
                    isFetching,
                    pagination,
                    showSkeletons: isFetching,
                    sorting,
                    columnVisibility: {
                        action: HasPermission("get user loyalties"),
                    },
                }}
            />
        </Box>
    )
}
export default Loyalties
