import { DateTime } from "luxon"

const handleReceiptExport = (data) =>
    data?.map((item) => ({
        date: DateTime.fromISO(item.created_at).toFormat("ff"),
        // fs_number: item.fs_number,
        invoice_number: `CA-${item.receipt_number?.toString().padStart(8, "0")}`,
        customer_name: item.full_name,
        customer_phone: item.user.phone,
        tin: item.service_number,
        amount: item.amount,
        vat: item.vat,
        type: item.type,
        last_updated: item?.updated_at ? DateTime.fromISO(item.updated_at).toFormat("ff") : "",
    }))
export const handleDriverReceiptExport = (data) => data?.map((item) => {
    // eslint-disable-next-line camelcase
    const { user, bank_name, ...rest } = item;
    return {
        ...rest,
        created_at: DateTime.fromISO(item.created_at).toFormat("ff"),
        invoice_number: `CA-${item.receipt_number?.toString().padStart(8, "0")}`,
        customer_phone: item.user.phone,
        method: item.method === "" ? item.bank_name : item.method,
        last_updated: item?.updated_at ? DateTime.fromISO(item.updated_at).toFormat("ff") : "",
    };
});
export default handleReceiptExport