import React, { useEffect, useMemo } from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Box,
    CircularProgress,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { useMutation } from "react-query"
import { useForm, Controller } from "react-hook-form"

import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"

function LoyaltyAdjustment({ open, onClose, loyaltyData, refetch }) {
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            balance: 0,
            credit_debit_indicator: "CREDIT",
        },
    })
    const loyalty = useMemo(
        () => ({
            type: loyaltyData?.loyalty_balance?.customer_type,
            id: loyaltyData?.loyalty_balance?.user_id,
        }),
        [loyaltyData]
    )
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // MUTATION
    const { mutate, isLoading } = useMutation(
        (formData) =>
            axiosPrivate.post(
                `/system/point_adjustment/${loyalty.id}`,
                formData
            ),
        {
            onSuccess: () => {
                refetch()
                enqueueSnackbar("updated successfully", {
                    variant: "success",
                })
                onClose()
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )
    // Reset form when dialog opens
    useEffect(() => {
        if (open) reset()
    }, [open, reset])

    const processSubmit = (data) => {
        // Convert string to decimal
        const adjustmentAmount = parseFloat(data.balance)
        mutate({
            ...data,
            customer_type: loyalty?.type,
            balance: adjustmentAmount,
        })
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Point Adjustment
                    </Typography>
                </Box>
            </DialogTitle>
            <form onSubmit={handleSubmit(processSubmit)}>
                <DialogContent>
                    <Box sx={{ mb: 3 }}>
                        <Controller
                            name="credit_debit_indicator"
                            control={control}
                            render={({ field }) => (
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Adjustment Type
                                    </FormLabel>
                                    <RadioGroup row {...field}>
                                        <FormControlLabel
                                            value="CREDIT"
                                            control={<Radio />}
                                            label="Credit (Add Points)"
                                        />
                                        <FormControlLabel
                                            value="DEBIT"
                                            control={<Radio />}
                                            label="Debit (Remove Points)"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />
                    </Box>

                    <Controller
                        name="balance"
                        control={control}
                        rules={{
                            required: "Point is required",
                            pattern: {
                                value: /^\d*\.?\d{0,2}$/,
                                message:
                                    "Please enter a valid decimal (up to 2 decimal places)",
                            },
                            validate: (value) =>
                                parseFloat(value) > 0 ||
                                "Point must be greater than zero",
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Point"
                                fullWidth
                                type="number"
                                inputProps={{ inputMode: "decimal" }}
                                error={!!errors.balance}
                                helperText={errors.balance?.message}
                                autoFocus
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        mt: 1,
                        pr: 3,
                        pt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Button
                        onClick={onClose}
                        size="small"
                        variant="contained"
                        sx={{
                            ":hover": { bgcolor: "#e4e4e4" },
                            backgroundColor: "#e4e4e4",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                    >
                        <Typography>Cancel</Typography>
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            bgcolor: "#fdbc3d",
                            ":hover": { bgcolor: "#fdbc3d" },
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                        type="submit"
                        onClick={handleSubmit(processSubmit)}
                        disabled={isLoading}
                    >
                        <Typography sx={{ color: "secondary.main" }}>
                            Apply
                        </Typography>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "black",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default LoyaltyAdjustment
